<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row no-gutters>
      <!-- FILTER OPTIONS -->
      <v-col cols="12">

        <v-row>
          <v-col cols="12" sm="6" lg="3">
            <DateRangePicker @update="onDateRangeUpdated"
              :def-dates="dates">
            </DateRangePicker>
          </v-col>
          <v-col cols="12" sm="6">
            <ScopeSelector
              @org-selected="onOrganizationSelected"
              @mch-selected="onMerchantSelected">
            </ScopeSelector>
          </v-col>
        </v-row>

      </v-col>

      <!-- STAT FIGURES -->
      <v-col cols="12" sm="6" lg="3">
        <v-row class="px-4">

          <v-col cols="12" v-if="[ 'manager', 'employee' ].indexOf(user.level) < 0">
            <base-material-stats-card
              color="secondary"
              icon="mdi-store"
              :title="$t('views.stats.merchants')"
              :value="`${ Number(stats.count.merchants).toLocaleString() }`"
              sub-icon="mdi-domain"
              :sub-text="orgName"
            />
          </v-col>

          <v-col cols="12">
            <base-material-stats-card
              color="primary"
              icon="mdi-script-text"
              :title="$t('views.stats.completedOrders')"
              :value="`${ Number(stats.count.completedOrders).toLocaleString() }`"
              sub-icon="mdi-cancel"
              :sub-text="$t('views.stats.refundedAmongOrders', { n: Number(stats.count.refundedOrders).toLocaleString() })"
            />
          </v-col>

          <v-col cols="12">
            <base-material-stats-card
              color="success"
              icon="mdi-cash-multiple"
              :title="$t('views.stats.total')"
              :value="`$${ Number(stats.total.amount).toLocaleString() }`"
              sub-icon="mdi-cancel"
              :sub-text="$t('views.stats.refundedAmongTotal', { n: Number(stats.total.refunded).toLocaleString() })"
            />
          </v-col>

        </v-row>
      </v-col>

      <!-- TABBED PANEL -->
      <v-col cols="12" sm="6" lg="9">
        <v-card>
          <v-tabs color="indigo" icons-and-text v-model="activeTab" @change="onTabChanged">
            <v-tab>Change Logs<v-icon>mdi-notebook</v-icon></v-tab>
            <v-tab>Distributions<v-icon>mdi-chart-pie</v-icon></v-tab>

            <!-- TAB - CHANGE LOGS -->
            <v-tab-item class="px-4">
              <v-card outlined>
                <!-- <v-card-title>Recent Changes</v-card-title> -->
                <v-card-text>
                  <div class="pt-2 pb-6">
                    <h3 class="mb-2">
                      2022/5/2 - Portal v1.1.11
                    </h3>
                    <div class="text--primary">
                      <ul>
                        <li>新增「櫃檯／條碼」管理功能。</li>
                      </ul>
                    </div>
                  </div>
                  <div class="pt-2 pb-6">
                    <h3 class="mb-2">
                      2022/5/2 - Portal v1.0.15
                    </h3>
                    <div class="text--primary">
                      <ul>
                        <li>新增「商家移轉組織」功能（僅限系統管理員使用）。</li>
                      </ul>
                    </div>
                  </div>
                  <div class="pt-2 pb-6">
                    <h3 class="mb-2">
                      2021/10/27 - Portal v1.0.13
                    </h3>
                    <div class="text--primary">
                      <ul>
                        <li>新增「加值服務、捐一點捐款記錄」查詢頁面。</li>
                        <li>日期選擇器添加捷徑按鈕，並預設選擇今日（以節省查詢效能）。</li>
                        <li>更改表格分頁按鈕樣式。</li>
                      </ul>
                    </div>
                  </div>
                  <div class="pt-2 pb-6">
                    <h3 class="mb-2">
                      2021/10/12 - Portal v1.0.12
                    </h3>
                    <div class="text--primary">
                      <ul>
                        <li>加入TapPay、LINE Pay、悠遊付的新增刪改表單欄位驗證機制。</li>
                        <li>訂單列表以圖示以取代部分支付方式純文字顯示。</li>
                      </ul>
                    </div>
                  </div>
                  <div class="pt-2 pb-6">
                    <h3 class="mb-2">
                      2021/8/12 - Portal v1.0.11
                    </h3>
                    <div class="text--primary">
                      <ul>
                        <li>新增部分支付服務之批次新增功能。</li>
                        <li>更新支付服務新增刪改API與功能。</li>
                        <li>新增部分支付服務與方式之圖示以取代純文字顯示。</li>
                      </ul>
                    </div>
                  </div>
                  <div class="pt-2 pb-6">
                    <h3 class="mb-2">
                      2021/8/9 - Portal v1.0.9
                    </h3>
                    <div class="text--primary">
                      <ul>
                        <li>新增商家設定：員工管理、加值服務設定等</li>
                        <li>新增QPoints點數兌換記錄查詢頁面（請先為商家開通加值服務）</li>
                        <li>補齊部分中文語言。</li>
                      </ul>
                    </div>
                  </div>
                  <div class="pt-2 pb-6">
                    <h3 class="mb-2">
                      2021/7/25 - Portal v1.0.7
                    </h3>
                    <div class="text--primary">
                      <ul>
                        <li>總覽頁面改成以標籤頁形式呈現。</li>
                        <li>後端調整DB查詢效能。</li>
                      </ul>
                    </div>
                  </div>
                  <div class="pt-2 pb-6">
                    <h3 class="mb-2">
                      2021/7/16 - Portal v1.0.6
                    </h3>
                    <div class="text--primary">
                      <ul>
                        <li>修復支付方式有時無法刪除的bug。</li>
                        <li>後端新增外部API（External API）供外部服務存取。</li>
                      </ul>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- TAB - DISTRIBUTION CHARTS -->
            <v-tab-item>
              <v-container>
                <v-row>

                  <!-- DISTRIBUTION - PAY-TYPES VS AMOUNTS -->
                  <v-col cols="12" md="6">
                    <base-material-card
                      id="pie"
                      color="success"
                      icon="mdi-currency-usd"
                      :title="$t('views.stats.distributionByAmount')"
                      class="px-4 py-3"
                    >
                      <chartist
                        :data="chart.payTypesByAmount.data"
                        :options="chart.payTypesByAmount.options"
                        type="Pie"
                        style="max-height: 250px;"
                      />

                      <v-divider></v-divider>

                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">{{ $t('views.stats.paymentService') }}</th>
                              <th class="text-left">$</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="row in chart.payTypesByAmount.tableRows" :key="row.key">
                              <td>{{ row.key }}</td>
                              <td>${{ new Number(row.val).toLocaleString() }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>

                    </base-material-card>
                  </v-col>
                  <!-- DISTRIBUTION - PAY-TYPES VS #ORDERS -->
                  <v-col cols="12" md="6">
                    <base-material-card
                      id="pie"
                      color="primary"
                      icon="mdi-script-text"
                      :title="$t('views.stats.distributionByOrders')"
                      class="px-4 py-3"
                    >
                      <chartist
                        :data="chart.payTypesByOrders.data"
                        :options="chart.payTypesByOrders.options"
                        type="Pie"
                        style="max-height: 250px;"
                      />

                      <v-divider></v-divider>

                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">{{ $t('views.stats.paymentService') }}</th>
                              <th class="text-left">{{ $t('views.stats.numOfCompletedOrders') }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="row in chart.payTypesByOrders.tableRows" :key="row.key">
                              <td>{{ row.key }}</td>
                              <td>{{ new Number(row.val).toLocaleString() }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>

                    </base-material-card>
                  </v-col>

                </v-row>
              </v-container>
            </v-tab-item>


          </v-tabs>
        </v-card>
      </v-col>


      <!-- DISTRIBUTIONS -->

      

    </v-row>
  </v-container>
</template>

<script>
  import DateRangePicker from '@/components/DateRangePicker';
  import ScopeSelector from '@/components/ScopeSelector';

  import axios from 'axios';
  import moment from 'moment';

  export default {
    components: {
      DateRangePicker,
      ScopeSelector
    },

    mounted() {
      this.loadStats();
    },

    methods: {
      onDateRangeUpdated(dates) {
        this.dates = dates;
        this.loadStats();
      },
      onOrganizationSelected(org) {
        this.scope.organization = org;
        this.loadStats();
      },
      onMerchantSelected(merchant) {
        this.scope.merchant = merchant;
        this.loadStats();
      },
      onTabChanged(n) {
        if (n == 1 && this.shouldLoadDistributions) {
          this.shouldLoadDistributions = false;
          this.fetchPayTypeDistribution();
        }
      },

      fetchSimpleStats() {
        axios.get(this.urlGetStats)
        .then(response => {
          this.stats = response.data;
        })
        .catch(err => {
          console.error(`Cannot fetch stats`, err);
        });
      },
      fetchPayTypeDistribution() {
        axios.get(this.urlGetPayTypeDistr)
        .then(response => {
          this.toPieChartFormat(response.data.amount, 'payTypesByAmount');
          this.toPieChartFormat(response.data.orders, 'payTypesByOrders');
        })
        .catch(err => {
          console.error(`Cannot fetch payment type distribution`, err);
        });
        this.shouldLoadDistributions = false;//NOTE: Avoid repetitive loading.
      },
      loadStats() {
        this.fetchSimpleStats();
        if (this.activeTab == 1)
          this.fetchPayTypeDistribution();
        else
          this.shouldLoadDistributions = true;
      },


      toPieChartFormat(data, chartName) {
        let labels = [], series = [], tableRows = [];
        for (let key in data) {
          labels.push(key);
          series.push(data[key]);
          tableRows.push({ key, val: data[key] });
        }
        this.chart[chartName] = {
          data: { labels, series },
          options: {
            chartPadding: 16,
            labelPosition: 'outside',
            classNames: { label: 'pie-chart-label' },
            // labelInterpolationFnc: value => value[0]
          },
          tableRows
        };
      }

    },

    data () {
      return {
        dates: [
          moment().startOf('day').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ],
        scope: {
          organization: null,
          merchant: null
        },

        stats: {
          count: {
            merchants: 0, orders: 0,
            completedOrders: 0, refundedOrders: 0, failedOrders: 0
          },
          total: {
            amount: 0, refunded: 0
          }
        },

        chart: {
          payTypesByAmount: {},
          payTypesByOrders: {}
        },

        shouldLoadDistributions: true,
        activeTab: 0
      }
    },

    computed: {
      urlGetStats() {
        let from = moment(this.dates[0]).startOf('day');
        let to = moment(this.dates[1]).endOf('day');
        
        if (this.$store.getters.user['merchant']) {
          return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/${this.$store.getters.user['merchant']['id']}/stats`
            + `?from=${from.unix()}&to=${to.unix()}`;
        }
        if (this.$store.getters.user['organization']) {
          return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/orgs/${this.$store.getters.user['organization']['alias']}/stats`
            + `?from=${from.unix()}&to=${to.unix()}`
            + (this.scope.merchant? `&merchantId=${this.scope.merchant['merchant_id']}`: '');
        }

        // Otherwise it's superadmin.
        return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/stats`
          + `?from=${from.unix()}&to=${to.unix()}`
          + (this.scope.organization? `&orgId=${this.scope.organization['id']}`: '')
          + (this.scope.merchant? `&merchantId=${this.scope.merchant['merchant_id']}`: '');
      },
      urlGetPayTypeDistr() {
        let from = moment(this.dates[0]).startOf('day');
        let to = moment(this.dates[1]).endOf('day');
        
        if (this.$store.getters.user['merchant']) {
          return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/${this.$store.getters.user['merchant']['id']}/pay-type-distr`
            + `?from=${from.unix()}&to=${to.unix()}`;
        }
        if (this.$store.getters.user['organization']) {
          return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/orgs/${this.$store.getters.user['organization']['alias']}/pay-type-distr`
            + `?from=${from.unix()}&to=${to.unix()}`
            + (this.scope.merchant? `&merchantId=${this.scope.merchant['merchant_id']}`: '');
        }
        
        // Otherwise it's superadmin.
        return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/pay-type-distr`
          + `?from=${from.unix()}&to=${to.unix()}`
          + (this.scope.organization? `&orgId=${this.scope.organization['id']}`: '')
          + (this.scope.merchant? `&merchantId=${this.scope.merchant['merchant_id']}`: '');
      },

      totalSales () {
        return this.sales.reduce((acc, val) => acc + val.salesInM, 0)
      },

      orgAlias() {
        if (this.$store.getters.user['organization'])
          return this.$store.getters.user['organization']['alias'];
        return undefined;
      },
      orgName() {
        if (this.$store.getters.user['organization'])
          return this.$store.getters.user['organization']['name'];
        return undefined;
      },

      user() {
        return this.$store.getters.user;
      }
    }

  }
</script>

<style scoped>
.pie-chart-label {
  font-weight: 900;
  color: black;
}
</style>

<style lang="sass">
  #coloured-line
    .ct-series-a .ct-line,
    .ct-series-a .ct-point
      stroke: #00cae3 !important

  #multiple-bar
    .ct-series-a .ct-bar
      stroke: #00cae3 !important

    .ct-series-b .ct-bar
      stroke: #f44336 !important

  #pie
    .ct-series-a .ct-slice-pie
      fill: #00CAE3 !important

    .ct-series-b .ct-slice-pie
      fill: #4CAF50 !important
</style>
